<template>
  <div v-if="userData">
    <admin-dashboard v-if="userData.roles[0].id==14" />
    <merchant-dashboard
      v-if="userData.role === 1"
      :user-data="userData"
    />
    <waffarha-dashboard
      v-if="userData.roles[0].id==24"
      :user-data="userData"
    />
  </div>
</template>

<script>
import AdminDashboard from './admin-dashboard/AdminDashboard.vue'
import MerchantDashboard from './merchant-dashboard/MerchantDashboard.vue'
import WaffarhaDashboard from './waffarha-dashboard/WaffarhaDashboard.vue'

export default {
  components: { AdminDashboard, MerchantDashboard, WaffarhaDashboard },
  data() {
    return {
      userData: JSON.parse(localStorage.user_info),
    }
  },
}
</script>

<style>

</style>
