<template>
  <b-row>

    <static-cards
      :dashboard="dashboard"
      :cashcall-balance="cashCallBalance"
      :one-card-balance="oneCardBalance"
    />
    <b-col
      cols="12"
      md="8"
      lg="11"
    >
      <latest-transactions
        :dashboard="dashboard.latest_transactions"
        @filter="getDahboard"
      />
    </b-col>

    <b-col
      cols="12"
      md="6"
    >
      <top-amount-transactions :dashboard="dashboard.top_amount_transaction" />
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StaticCards from './components/StaticCards.vue'
import LatestTransactions from './components/LatestTransactions.vue'
import TopAmountTransactions from './components/ClientTransactionsAmount.vue'

export default {
  components: {
    StaticCards, LatestTransactions, TopAmountTransactions,
  },
  data() {
    return {
      dashboard: {},
      cashCallBalance: {},
      oneCardBalance: 0,
    }
  },
  mounted() {
    this.getDahboard()
    this.getCashCallBalance()
    this.getOneCardBalance()
  },
  setup() {
    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
    }
  },
  methods: {
    async getDahboard(params) {
      try {
        const { data } = await axios.get('dashboard', { params })
        this.dashboard = data.data
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },

    async getCashCallBalance() {
      try {
        const { data } = await axios.post('cashcall/balance')
        // eslint-disable-next-line prefer-destructuring
        this.cashCallBalance = data.data.balance_result[0]
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async getOneCardBalance() {
      try {
        const { data } = await axios.post('gaming-check-balance')
        // eslint-disable-next-line prefer-destructuring
        console.log(data.balance)
        this.oneCardBalance = data.balance
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style>

</style>
