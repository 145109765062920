<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-card class="text-center">
        <b-link class="brand-logo">
          <img
            v-img
            :src="require('@/assets/images/logo/zero-cash.png')"
            width="200"
            class="my-2"
          >
        </b-link>
        <b-card-title class="my-2">
          Welcome to ZeroCash! 👋
        </b-card-title>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      merchantData: {},
      loader: false,
    }
  },
  mounted() {
  },
  methods: {

  },

}
</script>

<style>

</style>
