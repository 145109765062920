<template>
  <b-row class="match-height">
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Shops"
        :statistic="dashboard.merchants"
        icon="ShoppingCartIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Customers"
        :statistic="dashboard.customers"
        icon="UsersIcon"
      />
    </b-col>

    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Branches"
        :statistic="dashboard.branches"
        icon="LayoutIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Transactions"
        :statistic="dashboard.transactions"
        icon="DollarSignIcon"
      />
    </b-col>

    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Merchant Users"
        :statistic="dashboard.merchant_users"
        icon="UserIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="CashCall Current Balance"
        :statistic="roundToTwo(cashcallBalance.current_balance)"
        icon="MonitorIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="Sub CashCall Wallet"
        :statistic="roundToTwo(dashboard.sub_cashcall_wallet)"
        icon="MonitorIcon"
      />
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <statistic-card-horizontal
        statistic-title="One Card Balance"
        :statistic="roundToTwo(oneCardBalance)"
        icon="MonitorIcon"
      />
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: { StatisticCardHorizontal },
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
    cashcallBalance: {
      type: Object,
      default: () => {},
    },
    oneCardBalance: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    roundToTwo(num) {
      if (num) {
        return +(`${Math.round(`${num}e+2`)}e-2`)
      }
      return ''
    },
  },

}
</script>

<style>

</style>
